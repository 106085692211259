import React from 'react'
import projects from '../components/project'
import { Helmet } from 'react-helmet-async'
export const MoreWork = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <Helmet>
        <title>Projects</title>
        <meta name="description" content="This is my project page that shows what different projects I finished for different client in Web Development." />
      </Helmet>
    <h1 className="text-3xl font-bold text-center mb-8">My Projects</h1>
    <div className="grid grid-cols-1 md:grid-cols-2 md:grid-cols-2  gap-8">
      {projects.map((project, index) => (
        <div 
          key={index} 
          className="group relative overflow-hidden rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300"
        >
          <img 
            src={project.img} 
            alt={`Project ${index + 1}`} 
            className="w-full h-80 object-cover transition-transform duration-300 transform group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
            <a 
              href={project.link} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white text-lg font-semibold bg-orange-500 px-4 py-2 rounded-full hover:bg-orange-600"
            >
              Visit Site
            </a>
          </div>
        </div>
      ))}
    </div>
  </div>
  )
}
