import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export const BookingCalendar = ({ selectedDate, onDateChange }) => {
  return (
    <div className="my-4">
      <Calendar 
        onChange={onDateChange} 
        value={selectedDate}
        className="mx-auto"
      />
    </div>
  );
};
