import React, { useState } from 'react'
import { ContactForm } from '../components/ContactForm'
import { Helmet } from 'react-helmet-async'
import { BookingCalendar } from '../components/Booking/BookingCalender';
import { AvailableSlots } from '../components/Booking/Availableslots';
import { BookingConfirmation } from '../components/Booking/BookingConfirmation';
import { BookingForm } from '../components/Booking/BookingForm';

export const Contact = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState('');
  const [bookingDetails, setBookingDetails] = useState(null);

  const handleFormSubmit = (details) => {
    setBookingDetails({
      ...details,
      date: selectedDate.toDateString(),
      time: selectedSlot
    });
  };


  return (
    <div className='max-w-7xl mx-auto flex flex-col  justify-center px-4 min-h-screen'>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="This is a contact page, I only use forms to accept emails. I do not have any of my emails or numbers listed because I do not want people to spam me." />
      </Helmet>
        <h1 className='text-7xl opacity-80 py-4'>Get Started</h1>
        {/* <p className='opacity-60 w-full md:w-1/2 mx-auto'>Contact me either way. I don't want to make a form contact. Just copy either the email or phone number. I'll get back to you.</p> */}
        {/* <div className='bg-[#2b2d2d] flex flex-col items-center justify-center rounded h-80 my-4'>
            <h1 className='py-2 opacity-60'>Email</h1>
            <p className='opacity-80 text-2xl'>Winagencydesigns@gmail.com</p>

            <h1 className='py-2 opacity-60'>Phone</h1>
            <p className='opacity-80 text-2xl'>801-918-1587</p>
        </div> */}
        <ContactForm/>
        {/* <div className="container mx-auto p-4">

      {!bookingDetails ? (
        <>
          <BookingCalendar selectedDate={selectedDate} onDateChange={setSelectedDate} />
          <AvailableSlots selectedDate={selectedDate} onSelectSlot={setSelectedSlot} />
          <BookingForm onFormSubmit={handleFormSubmit} />
        </>
      ) : (
        <BookingConfirmation bookingDetails={bookingDetails} />
      )}
    </div> */}
    </div>
  )
}
